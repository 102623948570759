import * as React from "react"
import styled from 'styled-components';

//Components
import ProjectInfo from '../../components/ProjectInfo';
import ProjectHeader from '../../components/ProjectHeader';
import ProjectCaseStudy from '../../components/ProjectCaseStudy';
import ProjectSubHeader from "../../components/ProjectSubHeader";
import PrototypeDemo from "../../images/echo/echo-ship-video-demo.mp4"
import FooterWithPadding from '../../components/FooterWithPadding';

const EchoLogistics = () => {
  return (
    <Grid>
      <ProjectHeader 
        title="Echo Ship"
        subTitle="Developing a design system for rapid prototyping a sales demo"
      />
      <ProjectInfo 
        when="2019"
        platform="Web"
        team="2 PMs, 2 Developers"
        role="UI Developer"
        tools="React JS, Styled Components, VSCode, Storybook, Netlify, Git, Basecamp, Sketch, Figma"

        symbolPlatform="💻"
      />

      <div className="company-info">
        <span>Echo Global Logistics</span>
        (NASDAQ: ECHO) is a leading provider of technology-enabled transportation and supply chain management services. 
      </div>

      <div className="background">
        <p className="background-title">Background</p>
        <p className="background-text">
        I partnered with Echo while working at 8bit to help them develop a 
        prototype for their sales team. Echo's product team had used 8bit in the past to do similar
        work so I was onboarded quickly. For this project, I was given a complex set of design flows 
        and documented interactions that needed to be turned into a prototype. 
        How it was built was up to me and the PM I was working with. Eventually, we decided
        on using React for reusability purposes come to the final handoff to Echo's team.
        </p>
      </div>
      <div className="background">
        <p className="background-title">Project Highlights</p>
        <p className="background-text"><span className="star">⭐️</span><span>-</span>Personally developed hundreds of components and assembled them into a design system using Storybook JS.</p>
        <p className="background-text"><span className="star">⭐️</span><span>-</span>Used design system I built to assemble components into a full prototype built with Gatsby JS.</p>
        <p className="background-text"><span className="star">⭐️</span><span>-</span>Paired with senior engineer after I had completed static/functional components to add more advanced features like state machines, data inputs, and typeahead search.</p>

      </div>

      <ProjectSubHeader 
        title="Prototype Preview"
        button="View Design System"
        href="https://pedantic-brattain-beeae7.netlify.app/?path=/story/grids-template-page-grids--login"
      />

      <ProjectShowcaseCard>
        <video autoPlay muted loop controls>
          <source src={PrototypeDemo} type="video/mp4"/>
        </video>
      </ProjectShowcaseCard>
    <FooterWithPadding />
    </Grid>
  )
}

export default EchoLogistics




const Grid = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  max-width: 1110px;
  margin: 0 auto;

  .company-info {
    font-weight: 400;
    font-size: clamp( 16px,2vw,18px);
    line-height: 1.6;
    margin: 32px 0px;
    padding: 0px 0px 0px 11px;
    border-left: 3px solid var(--black-blue);
    color: var(--black-blue);
    span {
      font-weight: bold;
    }
    @media (max-width: 1200px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .background {
    .background-title {
      font-weight: 600;
      font-size: clamp( 16px,2vw,18px);
      padding: 0px 0px 16px 0px;
      color: #0c0c1d;
    }
    .background-text {
      font-weight: 400;
      font-size: clamp( 16px,2vw,18px);
      line-height: 1.6;
      color: var(--off-black);
			padding-bottom: 2.5%;
      span {
        font-weight: 900;
      }
    }
    p .star {
      padding-right: 1%;
    }
    p span {
      font-weight: 900;
      padding-right: .5%;
    }
    @media (max-width: 1200px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media (max-width: 650px) {
      p .star {
      display: none;
    }
  }

`

const ProjectShowcaseCard = styled.div`
  padding: 48px;
  margin-top: 24px;
  border: .1rem solid #c7c7cc;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
  border-radius: 1rem;
  background: rgba(221, 221, 221, 0.11);


  @media (max-width: 900px) {
    padding: 24px;
  }

  video {
    width: 100% !important;
    height: auto !important;
    box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
`